.futsal {
    margin-bottom: 40px;
    padding:10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
}


.futsal img {
    height: auto;
    width: 100%;
}

.futsal .futsalName h4 {
    font-size: 20px;
}

.futsal .costPerHour {
    margin-top:10px;
}

.futsal .costPerHour span {
    background-color: #35ac6040;
    padding: 5px 10px;
}