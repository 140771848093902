.header {
    background: #35ac60;
    color: #FFF;
    padding: 10px 0;
}

.header a.btn {
    color:#FFF;
}

.header .registerSection {
    margin-top: 5px;
}

.header .registerSection a {
    margin-left: 5px;
}